import React, { Component } from 'react';
import './SliderGlobal.css';
import './SliderEntrepreneurs.css';
import Slider from 'react-slick';
import { EntrepreneurSvg } from './EntrepreneurSvg'
import { allEntrepreneurs } from "../../cms/entrepreneurs"
//import { attempt } from 'lodash';
import {urlWithUtms} from '../../utils/urlWithUtms'

export default class SliderEntrepreneurs extends Component {
    
    setContent(index) {
        const container = document.getElementById('Entrepreneurs--Details');
        if(index==="close") {
            container.style.display = "none";
            const sliderElement = document.getElementById('Entrepreneurs--Slider');
            const sliderDetails = sliderElement.getBoundingClientRect().top + window.pageYOffset -75;
            window.scrollTo({top: sliderDetails, behavior: 'smooth'});
        } else {
            container.style.display = "block";
            const entrepreneur = allEntrepreneurs[index.index];
            const contents = document.getElementById('Entrepreneurs--Details-Content');
            let inners = `<div class='Entrepreneurs--Details-Container'>
                <div class='Entrepreneurs--Details-Name'>${entrepreneur.name}</div>
                <div class='Entrepreneurs--Details-Title'>${entrepreneur.title}</div>
                <hr />
                <div class='Entrepreneurs--Details-Description'>${entrepreneur.description}</div>
                <div class='Entrepreneurs--Details-Actions'>`;
            if(entrepreneur.watch !== "") inners += `<span class="link"><a target="_blank" href="${entrepreneur.watch}">WATCH</a></span>`;
            
            if(entrepreneur.read !== "") inners += `<span class="link"><a target="_blank" href="${urlWithUtms(entrepreneur.read)}">READ</a></span>`;
            if(entrepreneur.listen !== "") inners += `<span class="link"><a target="_blank" href="${entrepreneur.listen.split('.js?')[0]}">LISTEN</a></span>`;
            inners += `<span class="mobile-break"></span>`;
            if(entrepreneur.apple !== "") inners += `<span class="link-cast"><a target="_blank" href="${entrepreneur.apple}"><img src="/images/Slider/apple.svg" /></a></span>`;
            if(entrepreneur.spotify !== "") inners += `<span class="link-cast"><a target="_blank" href="${entrepreneur.spotify}"><img src="/images/Slider/spotify.svg" /></a></span>`;
            if(entrepreneur.google !== "") inners += `<span class="link-cast"><a target="_blank" href="${entrepreneur.google}"><img src="/images/Slider/google.svg" /></a></span>`;
            if(entrepreneur.casts !== "") inners += `<span class="link-cast"><a target="_blank" href="${entrepreneur.casts}"><img src="/images/Slider/cast.svg" /></a></span>`;
            contents.innerHTML = inners + `</div></div>`;
            const detailsElement = document.getElementById('Entrepreneurs--Details');
            const yDetails = detailsElement.getBoundingClientRect().top + window.pageYOffset -50;
            window.scrollTo({top: yDetails, behavior: 'smooth'});
        }
    }

    getSvg(image) {
        return EntrepreneurSvg({image});
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesPerRow: 4,
            rows: 2,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 993,
                    settings: {
                        slidesPerRow: 3,
                        rows: 2,
                    },
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesPerRow: 2,
                        rows: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesPerRow: 1,
                        rows: 3,
                    },
                },
            ],
        };
        return (
            <div id="Entrepreneurs--Slider" className="Entrepreneurs--Slider">
                <Slider {...settings}>
                    {allEntrepreneurs.map((element, index) => <div key={index} className={"Cube entrepreneur" + index} onClick={event => {
                          event.preventDefault()
                          this.setContent({index})
                        }}>
                       
                        <figure className="Image-Bg">
                             {this.getSvg(element.image)}
                        </figure>
                        <figure className="Side Left">
                            <p>{(element.name.includes('&')) ? element.name.split('&')[0] +"&" : element.name.split(' ')[0]} <br />
                            {(element.name.includes('&')) ? element.name.split('&')[1] : element.name.split(' ')[1]}<br/>
                                <span>{element.company}</span>
                            </p>
                        </figure>
                    </div>
                    )}
                </Slider>
                <div id="Entrepreneurs--Details" className="Entrepreneurs--Details">
                    <div className="close" onClick={event => {
                          event.preventDefault()
                          this.setContent("close")
                        }}><img src="/images/close.svg" /></div>
                    <div id="Entrepreneurs--Details-Content"></div>
                </div>
            </div>
        );
    }
}
