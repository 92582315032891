import React from 'react';

export const EntrepreneurSvg = props => {

    return <div className="svg">
             <div className="desktop">
				<svg className="row1" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 426.5 369.3" style={{enableBackground:"new 0 0 426.5 369.3"}}>
					<g id="mirrored" transform="translate(-1349.626 -2216.419)">
						<g id="imageclip">
							<defs>
								<path id="cliparea" d="M1765.8,2225.3l-202.7,117v234l202.7-117V2225.3z"/>
							</defs>
							<clipPath id="cliparea_2_">
								<use href="#cliparea"  style={{overflow:"visible"}}/>
							</clipPath>
							<g style={{clipPath:"url(#cliparea_2_)"}}>
								<image style={{overflow:"visible"}} width="210" height="325" id="image" href={props.image}  transform="matrix(1.1233 0 0 1.1233 1563.1534 2220.657)"></image>
							</g>
						</g>
						<path id="cliparea_1_" className="st0" d="M1765.8,2225.3l-202.7,117v234l202.7-117V2225.3z"/>
						<ellipse id="Ellipse_18" className="st1" cx="1765.3" cy="2461.2" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_19" className="st1" cx="1765.3" cy="2227.2" rx="10.8" ry="10.8"/>
					</g>
					<g id="Group_2204_1_" transform="translate(-1349.626 -2216.419)">
						<path id="Path_1499-2_1_" className="st0" d="M1360,2459.3l202.7,117v-234l-202.7-117V2459.3z"/>
						<path id="Path_1500_1_" className="st0" d="M1562.7,2342.2"/>
						<ellipse id="Ellipse_18_1_" className="st1" cx="1360.4" cy="2461.2" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_25_1_" className="st1" cx="1562.9" cy="2340.9" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_19_1_" className="st1" cx="1360.4" cy="2227.2" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_72_1_" className="st1" cx="1562.9" cy="2574.9" rx="10.8" ry="10.8"/>
					</g>
				</svg>
				<svg className="row2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 426.5 369.3" style={{enableBackground:"new 0 0 426.5 369.3"}}>
					<g id="mirrored-2" transform="translate(-1349.626 -2216.419)">
						<g id="clippath2">
							<defs>
								<path id="cliparea-down" d="M1765.8,2342.8l-202.7-117v234l202.7,117V2342.8z"/>
							</defs>
							<clipPath id="entreclipped">
								<use href="#cliparea-down"  style={{overflow:"visible"}}/>
							</clipPath>
							<g style={{clipPath:"url(#entreclipped)"}}>
								<image style={{overflow:"visible"}} width="210" height="325" id="entreimage" href={props.image}  transform="matrix(1.0793 0 0 1.0793 1560.1261 2222.3691)"></image>
							</g>
						</g>
						<path id="cliparea-2_1_" className="st0" d="M1765.8,2342.8l-202.7-117v234l202.7,117V2342.8z"/>
						<ellipse id="Ellipse_18" className="st1" cx="1765.3" cy="2340.9" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_19" className="st1" cx="1765.3" cy="2574.9" rx="10.8" ry="10.8"/>
					</g>
					<g id="Group_2204-2" transform="translate(-1349.626 -2216.419)">
						<path id="Path_1499-2_1_" className="st0" d="M1360,2576.8l202.7-117v-234l-202.7,117V2576.8z"/>
						<path id="Path_1500_1_" className="st0" d="M1562.7,2459.9"/>
						<ellipse id="Ellipse_18_1_" className="st1" cx="1360.4" cy="2340.9" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_25_1_" className="st1" cx="1562.9" cy="2461.2" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_19_1_" className="st1" cx="1360.4" cy="2574.9" rx="10.8" ry="10.8"/>
						<ellipse id="Ellipse_72_1_" className="st1" cx="1562.9" cy="2227.2" rx="10.8" ry="10.8"/>
					</g>
				</svg>

			</div>
         </div>
    ;
}
