import React from 'react';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import Content from '../components/Content';
import Layout from '../components/Layout';
import './EntrepreneursPage.css';
import SliderEntrepreneurs from '../components/Slider/SliderEntrepreneurs';
// import WistiaVideo from '../components/WistiaVideo/WistiaVideo';

export const EntrepreneursPageTemplate = ({
    body,
    title,
    titleMobile,
    subtitle,
    featuredImage,
    formText,
}) => (
    <main className="Entrepreneurs">
        <PageHeader
            title={title}
            subtitle={subtitle}
            backgroundImage={featuredImage}
        />
        <section className="section Entrepreneurs--Section1">
            <div className="container">
                <h1 className="Entrepreneurs--H1">
                    <Content source={title} />
                </h1>
                <h1 className="Entrepreneurs--H1-mobile">
                    <Content source={titleMobile} />
                </h1>
                
                <div className="Entrepreneurs--P">
                    <Content source={body} />
                </div>
            </div>
        </section>
        <section className="section Slider--Container">
            <div className="container">
                <SliderEntrepreneurs />
            </div>
        </section>
    </main>
);

const EntrepreneursPage = ({ data: { page } }) => (
    <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
    >
        <EntrepreneursPageTemplate {...page.frontmatter} body={page.html} />
    </Layout>
);

export default EntrepreneursPage;

export const pageQuery = graphql`
    query EntrepreneursPage($id: String!) {
        page: markdownRemark(id: { eq: $id }) {
            ...Meta
            html
            frontmatter {
                title
                titleMobile
                template
                subtitle
                featuredImage
                formText
            }
        }
    }
`;
